  .rowBodyCell {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  .borderedCell{
    justify-content: center;
    text-align: center;
    align-items: center;
    border-width: 1;
    border-style: solid;
    border-radius: 32px;
    /* height: 20; */
    font-size: 12;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    padding: 0 10;
    display: flex;
  }
  .statusSelect{
    justify-content: center;
    text-align: center;
    align-items: center;
    border-width: 1;
    border-style: solid;
    border-radius: 32px;
    height: 30;
    font-size: 12 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    padding: 10;
  }
  .MuiFormControl-root > .MuiInputBase-root > .MuiSelect-select.MuiSelect-select {
    padding-right: 16px !important;
  }
  .table-component-description-container>*{
    margin:0
  }
  .bulk-uploads__table-row__main-container {
    display: flex;
  }

  .bulk-uploads__table-row__image {
    background: #E8E8E8;
    width: 50px;
    height: 50px;
  }

  .bulk-uploads__table-row__labels-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bulk-uploads__table-row__top-label {
    font-size: 12px;
    color: #3C3E49;
    font-weight: 400;
    margin-left: 30px;
  }

  .bulk-uploads__table-row__bottom-label {
    font-size: 12px;
    color: #AFB2C0;
    font-weight: 400;
    margin-left: 30px;
  }


  #table-component > .MuiSelect-selectMenu {
    min-width: 130px !important;
  }
